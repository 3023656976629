<template>
  <section>
    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Escolaridade</h2>
      </div>
      <div class="col-md-5" >

      </div>
    </div>

    <div class="pm-card" >
      <div class="col-md-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12 lg:col-12">
            <h4 style="font-size: 21px !important;">Matrículas</h4>
          </div>
          <div class="field col-12 md:col-3 lg:col-3">
            <label>Matrícula</label>
            <pm-InputText v-model="servidor.matricula"  />
          </div>

          <div class="field col-12 md:col-4 lg:col-3">
            <label>Contrato</label>
            <select class="p-inputtext p-component" v-model="servidor.contrato"
              style="appearance: revert !important;width: 100% !important;" >
                <option value="" disabled selected> -- Escolha um Contrato -- </option>
                <option v-for="n in contratos" :key="n" :value="n" >{{n}}</option>
            </select>
          </div>

          <div class="field col-12 md:col-1 lg:col-1">
            <label for="year">Ano</label>
            <pm-InputNumber id="year" v-model="servidor.ano" :useGrouping="false" aria-describedby="number-error" :min="1960" :max="2050" />
          </div>

          <div class="field col-12 md:col-1 lg:col-1" style="padding-top: 38px;">
            <pm-Button
              type="button"
              @click="AddMatricula()"
              icon="pi pi-plus-circle"
              label=""
              title="Acrescentar matrícula e contrato"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;"
            >
            </pm-Button>
          </div>

          <div class="field col-12 md:col-12 lg:col-12" v-if="servidores">
            <table class="table table-hover" v-if="servidores.length > 0">
              <thead>
                <tr>
                  <th>Matricula</th>
                  <th>Contrato</th>
                  <th>Ano</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-for="s in servidores" :key="s">
                <tr>
                  <td>{{s.codigo}}</td>
                  <td>{{s.contrato}}</td>
                  <td>{{s.ano}}</td>
                  <td>
                    <pm-Button
                      type="button"
                      @click="EditMatriculaOpt(s)"
                      icon="pi pi-pencil"
                      label=""
                      title="Editar matrícula e contrato"
                      class="p-button-sm btn-color table_style"
                      style="margin-right: 5px;"
                    >
                    </pm-Button>
                    <pm-Button
                      type="button"
                      @click="RemoveMatriculaOpt(s)"
                      label=""
                      title="Remover"
                      icon="pi pi-trash"
                      class="p-button-danger p-button-sm btn-color table_style"
                      style="margin-right: 5px;"
                    >
                    </pm-Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr style="background-color: #93979b;width: 100%;">

          <div class="field col-12 md:col-12 lg:col-12">
            <h4 style="padding-top: 15px;font-size: 21px !important;">Formações Acadêmicas</h4>
          </div>
          <div class="field col-12 md:col-3 lg:col-3">
            <label>Nível</label>
            <select class="p-inputtext p-component" v-model="formacao.nivel"
              style="appearance: revert !important;width: 100% !important;" >
                <option value="" disabled selected> -- Escolha uma Formação -- </option>
                <option v-for="n in nivel" :key="n" :value="n" >{{n}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-1 lg:col-1">
            <label>Situacao</label>
            <select class="p-inputtext p-component" v-model="formacao.situacao"
              style="appearance: revert !important;width: 100% !important;" >
                <option  disabled selected> -- Escolha uma situacao -- </option>
                <option v-for="n in situacoes" :key="n" :value="n" >{{n}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-5 lg:col-5">
            <label>Curso e escola</label>
            <pm-InputText v-model="formacao.curso"  />
          </div>
          <div class="field col-12 md:col-1 lg:col-1">
            <label for="year">Ano</label>
            <pm-InputNumber id="year" v-model="formacao.ano" :useGrouping="false" aria-describedby="number-error" :min="1960" :max="2050" />
          </div>
          <div class="field col-12 md:col-1 lg:col-1">
            <label >Duração(horas)</label>
            <pm-InputNumber  v-model="formacao.duracao" :useGrouping="false" :min="1" />
          </div>
          <div class="field col-12 md:col-1 lg:col-1" style="padding-top: 38px;">
            <pm-Button
              type="button"
              @click="AddFormacao()"
              icon="pi pi-plus-circle"
              label=""
              title="Acrescentar Formação do Servidor"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;"
            >
            </pm-Button>
          </div>

          <div class="field col-12 md:col-12 lg:col-12" v-if="formacaos">
            <table class="table table-hover" v-if="formacaos.length > 0">
              <thead>
                <tr>
                  <th>Nivel</th>
                  <th>Curso</th>
                  <th>Situação</th>
                  <th>Duracao</th>
                  <th>Ano</th>
                  <th>Anexo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-for="s in formacaos" :key="s">
                <tr>
                  <td>{{s.nivel}}</td>
                  <td>{{s.curso}}</td>
                  <td>{{s.situacao}}</td>
                  <td>{{s.duracao}}</td>
                  <td>{{s.ano}}</td>
                  <td>
                    <img
                      v-if="s.anexo_diploma"
                      style="width: 60px;"
                      :src="base_url + '/storage/' + s.anexo_diploma"
                      @click="fotoOpen(s.anexo_diploma)"
                    />
                  </td>
                  <td>
                    <pm-Button
                      type="button"
                      @click="ModalAnexo(s)"
                      icon="pi pi-upload"
                      label=""
                      title="Anexo"
                      class="p-button-sm btn-color table_style"
                      style="margin-right: 5px;"
                    >
                    </pm-Button>
                    <pm-Button
                      type="button"
                      @click="EditFormacaoOpt(s)"
                      icon="pi pi-pencil"
                      label=""
                      title="Editar matrícula e contrato"
                      class="p-button-sm btn-color table_style"
                      style="margin-right: 5px;"
                    >
                    </pm-Button>
                    <pm-Button
                      type="button"
                      @click="RemoveFormacaoOpt(s)"
                      label=""
                      title="Remover"
                      icon="pi pi-trash"
                      class="p-button-danger p-button-sm btn-color table_style"
                      style="margin-right: 5px;"
                    >
                    </pm-Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <va-modal v-model="modal4" hide-default-actions overlay-opacity="0.2">
      <slot>
        <div>
          <img :src="foto_grande" style="width:100%" />
        </div>
      </slot>
    </va-modal>

    <pm-Dialog header="Editar Matricula" v-model:visible="modal" :style="{width: '100vw'}"
     :maximizable="true" :modal="true">
      <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3 lg:col-3">
            <label>Matrícula</label>
            <pm-InputText v-model="servidor_edit.matricula"  />
          </div>

          <div class="field col-12 md:col-4 lg:col-3">
            <label>Contrato</label>
            <select class="p-inputtext p-component" v-model="servidor_edit.contrato"
              style="appearance: revert !important;width: 100% !important;" >
                <option value="" disabled selected> -- Escolha um Contrato -- </option>
                <option v-for="n in contratos" :key="n" :value="n" >{{n}}</option>
            </select>
          </div>

          <div class="field col-12 md:col-1 lg:col-1">
            <label for="year">Ano</label>
            <pm-InputNumber id="year" v-model="servidor_edit.ano" :useGrouping="false" aria-describedby="number-error" :min="1960" :max="2050" />
          </div>

          <div class="field col-12 md:col-1 lg:col-1" style="padding-top: 38px;">
            <pm-Button
              type="button"
              @click="EditMatricula()"
              icon="pi pi-pencil"
              label=""
              title="Editar matrícula e contrato"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;"
            >
            </pm-Button>
          </div>
      </div>
    </pm-Dialog>

    <pm-Dialog
      v-model:visible="modalMRmv"
      :breakpoints="{ '960px': '75vw', '640px': '50vw' }"
      :style="{ width: '50vw' }"
      header="Deletar Matricula?"
      >
      <div class="col-md-12">
        <button  class="button-snap p-button p-component p-button-sm btn-color" @click="RemoveMatricula" style="margin-right:5px">
            Remover Matricula
        </button>
        <button class="button-snap p-button p-button-danger p-component p-button-sm btn-color" @click="modalMRmv = !modalMRmv ">
            Cancelar
        </button>
      </div>
    </pm-Dialog>

    <pm-Dialog
      v-model:visible="modalFRmv"
      :breakpoints="{ '960px': '75vw', '640px': '50vw' }"
      :style="{ width: '50vw' }"
      header="Deletar Formação?"
      >
      <div class="col-md-12">
        <button  class="button-snap p-button p-component p-button-sm btn-color" @click="RemoveFormacao" style="margin-right:5px">
            Remover Formação
        </button>
        <button class="button-snap p-button p-button-danger p-component p-button-sm btn-color" @click="modalFRmv = !modalFRmv ">
            Cancelar
        </button>
      </div>
    </pm-Dialog>

    <pm-Dialog header="Editar Formação" v-model:visible="modal2" :style="{width: '100vw'}"
     :maximizable="true" :modal="true">
      <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3 lg:col-3">
            <label>Nível</label>
            <select class="p-inputtext p-component" v-model="formacao_edit.nivel"
              style="appearance: revert !important;width: 100% !important;" >
                <option value="" disabled selected> -- Escolha uma Formação -- </option>
                <option v-for="n in nivel" :key="n" :value="n" >{{n}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-1 lg:col-1">
            <label>Situacao</label>
            <select class="p-inputtext p-component" v-model="formacao_edit.situacao"
              style="appearance: revert !important;width: 100% !important;" >
                <option  disabled selected> -- Escolha uma situacao -- </option>
                <option v-for="n in situacoes" :key="n" :value="n" >{{n}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-5 lg:col-5">
            <label>Curso e escola</label>
            <pm-InputText v-model="formacao_edit.curso"  />
          </div>
          <div class="field col-12 md:col-1 lg:col-1">
            <label for="year">Ano</label>
            <pm-InputNumber id="year" v-model="formacao_edit.ano" :useGrouping="false" aria-describedby="number-error" :min="1960" :max="2050" />
          </div>
          <div class="field col-12 md:col-1 lg:col-1">
            <label >Duração(horas)</label>
            <pm-InputNumber  v-model="formacao_edit.duracao" :useGrouping="false" :min="1" />
          </div>

          <div class="field col-12 md:col-1 lg:col-1" style="padding-top: 38px;">
            <pm-Button
              type="button"
              @click="EditFormacao()"
              icon="pi pi-pencil"
              label=""
              title="Editar Formacao"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;"
            >
            </pm-Button>
          </div>
      </div>
    </pm-Dialog>

    <pm-Dialog header="Anexar Arquivo" v-model:visible="modal3" :breakpoints="{ '960px': '75vw', '640px': '50vw' }"
      :style="{ width: '50vw' }"
     :maximizable="true" :modal="true">
      <div class="field col-12 md:col-12">
        <label for="arquivo">Selecione o arquivo:</label>

        <pm-FileUpload
          name="anexo[]"
          :url="null"
          mode="basic"
          chooseLabel="Carregar"
          @select="defineArquivos"
          :customUpload="true"
          @uploader="processaArquivos"
          :multiple="true"
        />

        <pm-Button v-if="selectedFiles"
          type="button"
          @click="EnviaAnexo()"
          label="Atualizar Anexo"
          title="Atualizar Anexo"
          class="p-button-sm p-button-success btn-color table_style"
          style="margin-right: 5px;margin-top:5px"
          >
        </pm-Button>

      </div>
    </pm-Dialog>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  props: {
    pg: {
      default: 1,
    },
    id:{}
  },

  data() {
    return {
      contratos:["Concursado","Contratado"],
      nivel:[
        "Ensino Fundamental","Ensino Medio","Curso","Curso Treinamento","Curso de idiomas","Cursos de Educação Continuada","Curso de Hobby","Seminário","Conferência", "Curso Workshop","Certificação Profissional","Curso Profissionalizante","Curso Técnico","Formação Superior","Pos Graduação (Especialização)","Mestrado","Doutorado"
      ],
      situacoes:["Completo","Interrompido","Cursando"],
      servidor:[],
      servidor_edit:[],
      servidores:[],

      modal:false,
      modalMRmv:false,

      formacao:{
        situacao:"Completo"
      },
      formacao_edit:{
        situacao:"Completo"
      },
      formacaos:[],

      modal2:false,
      modalFRmv:false,

      modal3:false,
      selectedFiles:0,

      modal4:false,

      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      foto_grande:'',
    };
  },

  async beforeMount() {
    this.GetMatricula()
    this.GetFormacao()
  },
  methods: {
    async GetMatricula(){
      this.servidores = [];
      let res = await axios.get('matricula/'+this.id);
      this.servidores = res.data.data;
    },
    async AddMatricula(){
      const servidor = this.servidor
      const {matricula, contrato, ano} = servidor
      let res = await axios.post('matricula/'+this.id,{
        "codigo": matricula,
        "contrato": contrato,
        "ano": ano
      });
      this.servidor = []
      this.GetMatricula()
    },
    EditMatriculaOpt(servidor){
      this.modal = true;
      this.servidor_edit = servidor;
      this.servidor_edit.matricula = servidor.codigo;
    },
    async EditMatricula(){
      const servidor = this.servidor_edit
      const {servidor_id, matricula, contrato, ano, id} = servidor
      this.modal = false;
      let res = await axios.put('matricula/'+servidor_id+'/'+id,{
        "codigo": matricula,
        "contrato": contrato,
        "ano": ano
      });
      this.servidor_edit = []
      this.GetMatricula()
    },
    RemoveMatriculaOpt(servidor){
      this.modalMRmv = true
      this.servidor_edit = servidor
    },
    async RemoveMatricula(){
      const {servidor_id, id} = this.servidor_edit
      let res = await axios.delete('matricula/'+servidor_id+'/'+id);
      this.servidor_edit = []
      this.modalMRmv = false
      this.GetMatricula()
    },

    //formacao
    async GetFormacao(){
      this.formacaos = [];
      let res = await axios.get('formacao/'+this.id);
      this.formacaos = res.data.data;
    },
    async AddFormacao(){
      const {nivel,curso, duracao, ano, situacao} = this.formacao
      let res = await axios.post('formacao/'+this.id,{
        "nivel": nivel,
        "curso": curso,
        "duracao": duracao,
        "situacao": situacao,
        "ano": ano
      });
      this.GetFormacao();
      this.formacao = []
    },
    EditFormacaoOpt(servidor){
      this.modal2 = true;
      this.formacao_edit = servidor;
    },
    async EditFormacao(){
      const {nivel,curso, duracao, ano, situacao,id} = this.formacao_edit
      let res = await axios.put('formacao/'+this.id+'/'+id,{
        "nivel": nivel,
        "curso": curso,
        "duracao": duracao,
        "situacao": situacao,
        "ano": ano
      });
      this.GetFormacao();
      this.modal2 = false;
      this.formacao = []
      this.formacao_edit = []
    },
    RemoveFormacaoOpt(servidor){
      this.modalFRmv = true
      this.formacao_edit = servidor
    },
    async RemoveFormacao(){
      const {id} = this.formacao_edit
      let res = await axios.delete('formacao/'+this.id+'/'+id);
      this.GetFormacao();
      this.modalFRmv = false
    },
    ModalAnexo(servidor){
      this.servidor_anexo = []
      this.modal3 = true
      this.selectedFiles = 0
      this.servidor_anexo = servidor
    },
    defineArquivos(event) {
      this.selectedFiles = event.files;
    },
    async EnviaAnexo() {
      this.modal3 = false
      var formData = new FormData();
      formData.append("anexo_diploma", this.selectedFiles[0]);
      let res = await axios.post('anexo-diploma/'+this.id+'/'+this.servidor_anexo.id,formData);
      this.GetFormacao();
    },
    fotoOpen(diploma){
      this.foto_grande = this.base_url + "/storage/" + diploma;
      this.modal4 = true;
    }
  },
});
</script>
<style>
.table > :not(:first-child) {
    border-top: 1px solid currentColor;
}
</style>

